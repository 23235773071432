const array = [
  {
    path: '/administrador/patrocinadores/categorias',
    name: 'categorias_patrocinador',
    component: () => import('./pages/crudCategoriaPatrocinadores/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Categorias de patrocinadores',
    },
  },
  {
    path: '/administrador/patrocinadores/categorias/:category',
    name: 'categorias_patrocinador_list',
    component: () => import('./pages/crudPatrocinadores/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Patrocinadores',
    },
  },
]
export default array
