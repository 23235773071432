const array = [
  {
    path: '/events/list',
    name: 'events_commun',
    props: true,
    component: () => import('./pages/events.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Eventos',
      breadcrumb: [
        {
          text: 'Eventos',
          active: true,
        },
      ],
    },
  }
]

export default array
