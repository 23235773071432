const array = [
  {
    path: '/administrador/eventos/conf/lives/:event_id',
    name: 'events_lives_new',
    props: true,
    component: () => import('./pages/Lives.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Lives',
      breadcrumb: [
        {
          text: 'Lives',
          active: true,
        },
      ],
    },
  }
]

export default array
